@font-face {
  font-family: baloopaaji2;
  src: url('../fonts/baloopaaji2.ttf');
  font-weight: 'normal';
}

@font-face {
  font-family: baloopaaji2;
  src: url('../fonts/baloopaaji2-bold.ttf');
  font-weight: 'bold';
}
