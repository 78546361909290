@font-face {
  font-family: baloopaaji2;
  src: url("baloopaaji2.5408a4a0.ttf");
  font-weight: "normal";
}

@font-face {
  font-family: baloopaaji2;
  src: url("baloopaaji2-bold.e05cfe66.ttf");
  font-weight: "bold";
}

/*# sourceMappingURL=index.132c2254.css.map */
